//设备类型 1表示飞鹅 2表示易联云 3表示优获云 4表示商米T1 mini  5表示商米T2 mini
export const deviceType = new Map([
  [1, '飞鹅'],
  [2, '易联云'],
  [3, '菜小秘'],
  [4, '商米T1 mini'],
  [5, '商米T2 mini'],
]);
// 设备状态：// 0禁用// 1激活// 2空闲// 3试用// 4报损
export const deviceStatus = new Map([
  [3, '空闲'],
  [2, '试用'],
  [1, '激活'],
  [4, '报损'],
  [0, '禁用'],
]);
//商行分6个状态：-1待确认，0试用中，1已签约，2已回款，3已过期，4试用失败。  待删除
export const PACT_STATUS = new Map([
  [-1, '待确认'],
  [0, '已开户'],
  [2, '已回款'],
  [3, '已过期'],
  [4, '已停用'],
  [5, '已续费'],
]);
//会员服务类型 0-无 1-协同办包月 2-协同版包年
export const PRODUCT_TYPE_LIST = new Map([
  [0, '无'],
  [1, '协同版包月'],
  [2, '协同版包年'],
]);
//会员渠道 1-android 2-ios
export const Plat_Form_TYPE = new Map([
  [1, '安卓'],
  [2, 'IOS'],
]);
//会员服务 支付类型 1：微信，2：苹果支付 3：系统创建
export const Pay_TYPE_LIST = new Map([
  [1, '微信'],
  [2, '苹果支付'],
  [3, '系统创建'],
]);
//注册用户渠道统计 来源渠道
export const USER_CHANNEL = new Map([
  [3, 'POS打印单据'],
  [4, '档主端赊欠单分享'],
  [2, '官网'],
  [5, '公众号'],
  [1, 'APP分享'],
  [0, '其他'],
]);

//注册用户渠道统计 来源渠道的系统类型
export const USER_CHANNEL_TYPE = new Map([
  [1, 'POS收银机'],
  [2, '财务后台'],
  [3, '运营后台'],
  [4, '买家端'],
  [5, '货主端'],
  [6, '档主端'],
  [7, '基础版'],
]);

// 系统类型
export const STALL_SYSTEM_TYPE = new Map([
  [1, '正式商行'],
  [2, '测试商行'],
]);

export const STALL_TYPE = new Map([
  [1, '省心版'],
  [8, '便捷版'],
  [5, '基础版'],
  [7, '标准版'],
  [2, '专业版'],
  [6, '旗舰版'],
]);

export const BUSINESS_TYPE = {
  1: '蔬菜',
  2: '水果',
  3: '水产',
};

// 商行分类
export const STALL_TYPES = new Map([
  [0, '新开商行'],
  [1, '签约商行'],
  [3, '流失商行'],
  [2, '废弃商行'],
  [4, '试用商行'],
]);

// 续费状态
export const RENEW_STATUS = new Map([
  [1, '待新签'],
  [2, '待续费'],
  [3, '已过期'],
  [4, '已续费'],
]);

/**
 * 合同类型
 */
export const CONTRACT_TYPES = new Map([
  [1, '新签合同'],
  [2, '续费合同'],
  [3, '活动赠送'],
]);

/**
 * 管理模式
 */
export const STALL_SALE_TYPE = new Map([
  [1, '代卖【按批次】'],
  [2, '自营【按货品】'],
  [4, '自营【按批次】'],
  [3, '代卖【按批次】+ 自营【按货品】'],
  [5, '代卖【按批次】+ 自营【按批次】'],
]);

// 合同的系统类型
export const STALL_PACT_TYPE = new Map([
  [1, '专业版'],
  [2, '基础版'],
]);

// 筐子类型
// export const BASKET_TYPE=new Map([
//     [1,'大筐'],
//     [2,'中筐'],
//     [3,'小筐'],
// ])

// 单位规则
export const unitRules = new Map([
  [0, '无单位'],
  [1, '数量单位'],
  [2, '重量单位'],
  [3, '元/数量单位'],
  [4, '元/重量单位'],
  [5, '重量单位/数量单位'],
  [6, '元'],
]);

//注册用户渠道统计 来源渠道的系统类型
export const APPLICATION_NAME = new Map([
  ['pos', 'POS'],
  ['app', 'APP'],
  ['finance', '财务端'],
  ['admin', '运营端'],
  ['purchase', '买家通'],
  ['supplier', '货主通'],
  ['bdps', '每日菜价'],
  ['comservice', 'comservice'],
]);

//包装类型  1：定装 2：非定装 3：散装  5: 郊菜  7: 拆包
export const PACK_KIND_NAME = new Map([
  [1, '定装'],
  [2, '非定装'],
  [3, '散装'],
  [5, '郊菜'],
  [7, '拆包'],
]);

/**
 * 售卖方式
 */
export const SALE_TYPE = new Map([
  [1, '代卖'],
  [2, '自营'],
]);

/**
 * 根据商行类型返回货品默认saleType
 * @param {*} stallSaleType
 * @returns
 */
export const getDefaultGoodsSaleTypeByStall = (stallSaleType) => {
  //纯自营、自营按批次
  if (2 === stallSaleType || 4 === stallSaleType) {
    return 2;
  } else {
    return 1;
  }
};
