// 查看详情-合同管理
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  Form,
  Button,
  Input,
  message,
  Table,
  Modal,
  Row,
  Col,
  Radio,
  Divider,
  Checkbox,
} from 'antd';
import {
  getStallRoleList,
  getStallRoleDetail,
  addRole,
  updateRole,
  deleteRole,
  getSystemRoleCode,
  getPermissionList,
} from '../../../../state/action/stall';

import './device.less';
const FormItem = Form.Item;

const layout = { xs: 24, sm: 12, md: 24, lg: 24, xl: 24 };
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

@connect(
  (state) => {
    return { stall: state.stall };
  },
  {
    getStallRoleList: getStallRoleList,
    getStallRoleDetail: getStallRoleDetail,
    addRole: addRole,
    updateRole: updateRole,
    deleteRole: deleteRole,
    getSystemRoleCode: getSystemRoleCode,
    getPermissionList: getPermissionList,
  },
)
@Form.create()
class StallRoleManager extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      record: {},
      content: '',
      checkRoleList: [],
      searchName: '',
    };
  }

  componentDidMount() {
    this.getStallRoleList(this.props.stallId);
    this.props.getPermissionList();
  }

  async getStallRoleList(params) {
    this.setState({ loading: true });
    await this.props.getStallRoleList(params);
    this.setState({ loading: false });
  }

  async getSystemRoleCode(roleCode) {
    const record = this.state.record;
    let roleList = [];
    if (record.templateName === roleCode) {
      roleList = record.roleList;
    } else {
      const resRoleList = await this.props.getSystemRoleCode(roleCode);
      roleList = resRoleList.data;
    }
    this.props.form.setFieldsValue({ roleList: roleList });
  }

  //点击新增和编辑Modal弹框
  showModal = (record, visible, content, checkRoleList) => {
    this.setState({
      record,
      visible,
      content,
      checkRoleList: checkRoleList,
      searchName: '',
    });
  };

  // 新增, 修改
  addRoleInfo = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, value) => {
      if (!err) {
        const { record } = this.state;

        if (JSON.stringify(record) === '{}') {
          //新增
          const params = {
            roleName: value.roleName,
            templateName: value.templateName,
            roleList: value.roleList,
            stallId: this.props.stallId,
          };
          this.addRole(params);
        } else {
          const params = {
            roleName: value.roleName,
            templateName: value.templateName,
            roleList: value.roleList,
            stallId: this.props.stallId,
            roleId: record.roleId,
          };
          this.updateRole(params);
        }
      }
    });
  };

  async addRole(params) {
    const result = await this.props.addRole(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('新增成功');
      //刷新列表
      this.getStallRoleList(this.props.stallId);
    } else {
      message.error(result.msg);
    }
  }

  async updateRole(params) {
    const result = await this.props.updateRole(params);
    if (result.code === 200) {
      this.setState({ visible: false });
      message.success('编辑成功');
      //刷新列表
      this.getStallRoleList(this.props.stallId);
    } else {
      message.error(result.msg);
    }
  }

  async getRoleDetail(record) {
    const result = await this.props.getStallRoleDetail(record.id);
    if (result.code === 200) {
      this.showModal(result.data, true, '编辑角色', result.data.roleList);
    } else {
      message.error(result.msg);
    }
  }

  async deleteRoleInfo(record) {
    const result = await this.props.deleteRole({ roleId: record.id, stallId: this.props.stallId });
    if (result.code === 200) {
      message.success('删除成功');
      //刷新列表
      this.getStallRoleList(this.props.stallId);
    } else {
      message.error(result.msg);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { record, visible, content, loading, searchName } = this.state;
    const {
      stall: { roleList, permissionList },
    } = this.props;

    const dataSource = roleList ? roleList : [];
    // 角色模板
    let roleTemplateOptions = [
      { label: '档口管理员', value: 'stall_owner' },
      { label: '财务', value: 'stall_finance' },
      { label: '收银员', value: 'stall_cashier' },
      { label: '可开单销售', value: 'stall_sellGoods' },
      { label: '销售经理', value: 'stall_saler' },
      { label: '货主', value: 'stall_supplier' },
    ];
    //4.0.1 版本放开勾选的权限
    //还款——02.004.007 撤销还款——02.003.011 交账后改单——02.003.012 货主管理-02.006.001 结算管理-02.015.001 修改买家-02.004.004
    // 新增交账后的作废(02.003.008)
    // 新增新增结算费用(02.016.002)
    // 新增修改结算费用(02.016.004)
    // 新增删除结算费用(02.016.006)
    // 新增设置费用规则(02.015.006)
    // 新增代卖批次结算(02.015.002)
    // 新增取消代卖结算(02.015.005)
    // 新增代卖结算付款(02.006.006)

    // -----------------------------------------------740 新增start-----------------------------------------------

    //  开订单02.003.002
    //  录入白条  02.003.006
    //  开码单  02.003.007
    //  退货       02.003.013
    //  删除码单  02.003.014
    //  下单选择时间  02.003.015
    //  作废白条/订单 作废 交账前作废  02.003.016

    //  新增买家  02.004.002
    //  删除买家   02.004.005
    //  修改买家  02.004.008
    //  停用/启用买家  02.004.009
    //  设置买家预存（充值/退预存）   02.004.010

    //  新增货品  02.005.002
    //  修改货品    02.005.004

    //  修改货主信息   02.006.004
    //  停用货主      02.006.007
    //  撤销打款     02.006.008

    //  新增员工  02.007.002
    //  停用/启用员工 02.007.005
    //  修改员工  02.007.004

    //  删除库存操作记录   02.009.008
    //  售罄/取消售罄批次       02.009.006
    //  录入/修改采购成本  02.013.003
    //  分享结算单   批次结算分享   02.015.007
    // -----------------------------------------------740 新增 end-----------------------------------------------

    let operRoleList = [
      '02.003.002',
      '02.003.006',
      '02.003.007',
      '02.003.008',
      '02.003.011',
      '02.003.012',
      '02.003.013',
      '02.003.014',
      '02.003.015',
      '02.003.016',
      '02.003.030',

      '02.004.002',
      '02.004.004',
      '02.004.005',
      '02.004.007',
      '02.004.008',
      '02.004.009',
      '02.004.010',

      '02.005.002',
      '02.005.004',

      '02.006.001',
      '02.006.002',
      '02.006.004',
      '02.006.006',
      '02.006.007',
      '02.006.008',

      '02.007.001',
      '02.007.002',
      '02.007.004',
      '02.007.005',

      '02.009.002',
      '02.009.004',
      '02.009.005',
      '02.009.006',
      '02.009.008',
      '02.009.009',
      '02.009.040',

      '02.013.003',

      '02.015.001',
      '02.015.002',
      '02.015.005',
      '02.015.006',
      '02.015.007',

      '02.016.002',
      '02.016.004',
      '02.016.006',

      '02.017.002',
      '02.017.004',
    ];

    const columns = [
      {
        title: '角色名称',
        dataIndex: 'remark',
      },
      {
        title: '角色类型',
        dataIndex: 'name',
        render: (text, record) => {
          let roleName = text;
          roleTemplateOptions.map((r) => {
            if (record.name === r.value) {
              roleName = r.label;
            }
          });
          return roleName;
        },
      },
      {
        title: '操作',
        dataIndex: 'option',
        key: 'option',
        render: (_, record) => {
          return (
            <span>
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.getRoleDetail(record);
                }}
              >
                编辑
              </a>
              <Divider type='vertical' />
              <a
                href='/'
                onClick={(e) => {
                  e.preventDefault();
                  this.deleteRoleInfo(record);
                }}
              >
                删除
              </a>
            </span>
          );
        },
      },
    ];

    return (
      <div className='basketSettings'>
        <Row>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Button
              type='primary'
              icon='plus'
              onClick={() => this.showModal({}, true, '新增角色', [])}
            >
              新增角色
            </Button>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(_, index) => index}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
        />
        {visible && (
          <Modal
            title={content}
            visible={visible}
            onCancel={() => this.showModal({}, false)}
            footer={null}
            className={'deviceModalStyle basketSettingsModal'}
            width='700px'
          >
            <Form layout='inline' onSubmit={this.addRoleInfo}>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='角色名称'>
                    {getFieldDecorator('roleName', {
                      initialValue: record.roleName || '',
                      rules: [
                        {
                          required: true,
                          message: '请输入角色名称',
                        },
                        {
                          pattern: /^[\u4e00-\u9fa5a-zA-Z-z0-9]+$/i,
                          message: '只能输入中文、字母、数字!',
                        },
                      ],
                    })(<Input placeholder='请输入角色名称' maxLength={20} allowClear />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='角色模板'>
                    {getFieldDecorator('templateName', {
                      initialValue: JSON.stringify(record) === '{}' ? '' : record.templateName,
                      rules: [{ required: true, message: '必须选择一项' }],
                    })(
                      <Radio.Group
                        onChange={(e) => {
                          this.getSystemRoleCode(e.target.value);
                        }}
                        options={roleTemplateOptions}
                      />,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label=' ' colon={false}>
                    <Input
                      onChange={(e) => {
                        this.setState({
                          searchName: e.target.value,
                        });
                      }}
                      placeholder='请输入权限名称'
                      value={searchName}
                      allowClear
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col {...layout}>
                  <FormItem {...formItemLayout} label='特殊权限配置'>
                    {getFieldDecorator('roleList', {
                      initialValue: this.state.checkRoleList,
                      rules: [{ required: true, message: '必须选择一项' }],
                    })(
                      <Checkbox.Group>
                        {[...permissionList].map((record) => (
                          <Checkbox
                            style={{
                              width: '220px',
                              color: 'red',
                              display: record.name.includes(searchName) ? 'inline-block' : 'none',
                            }}
                            disabled={operRoleList.indexOf(record.code) === -1 ? true : false}
                            value={record.code}
                          >
                            {record.name}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>,
                    )}
                  </FormItem>
                </Col>
              </Row>
              <br />
              <Row style={{ textAlign: 'center' }}>
                <Button onClick={() => this.showModal({}, false)}>关闭</Button>
                <Button type='primary' htmlType='submit' style={{ marginLeft: '18px' }}>
                  保存
                </Button>
              </Row>
            </Form>
          </Modal>
        )}
      </div>
    );
  }
}

export default StallRoleManager;
